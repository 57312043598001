import image from '../../images/blog/healthier-planet.png'

export default {
  url: '/blog/healthier-planet',
  title: `Healthier planet, healthier you. Join us in celebrating Earth Day 2024.`,
  author: `AdhereTech`,
  publishDate: `2024-04-11`,
  image,
  body: `
    <p>
      In acknowledgement of World Earth Day 2024, we have been digging into the environmental impacts of medication nonadherence, and the potential solutions to slow the contribution to ecological degradation.
    </p>
    <p>
      At the heart of the issue lies the improper disposal of unused or expired medications; it has been estimated that up to £300 million of prescribed medicines are wasted each year (NHS England, 2015). When patients fail to adhere to their treatment regimens, they often end up with surplus medication. Disposing of these medications improperly by flushing them down the toilet or throwing them in the trash can lead to the contamination of waterways and soil (Ellis, 2018). 
    </p>
    <p>
      Pharmaceutical compounds introduced into the environment can have adverse effects on aquatic ecosystems. Studies have shown that certain medications, even when present in trace amounts, can disrupt the endocrine systems of aquatic organisms, interfere with reproduction, and compromise overall ecosystem health (Gonsioroski et al., 2020). In order to negate the effects of this, proper disposal mechanisms for unused medications must be readily available and widely promoted. This may involve the implementation of take-back programs, where patients can return unused medications to pharmacies or designated collection sites for safe disposal. 
    </p>
    <p>
      Furthermore, the persistence of pharmaceuticals in the environment poses a threat to human health. Through the consumption of contaminated water or food, these substances can find their way into the human body, potentially leading to long-term health complications (Küster and Adler, 2014). Additionally, the widespread presence of pharmaceuticals in the environment contributes to the development of antibiotic resistance (Polianciuc et al., 2020), which currently poses a global public health challenge. Raising awareness about the environmental consequences of improper medication disposal can empower individuals to take action and make informed choices about their medications.
    </p>
  `,
  references: [
    `Ellis, Maricha. “The Dangers of Improper Drug Disposal.” <em>Waste Today</em>, Waste Today, 9 Oct. 2018, www.wastetodaymagazine.com/article/the-dangerous-of-improper-drug-disposal/.`,
    `Gonsioroski, Andressa, et al. “Endocrine Disruptors in Water and Their Effects on the Reproductive System.” <em>International Journal of Molecular Sciences</em>, vol. 21, no. 6, 12 Mar. 2020, p. 1929, https://doi.org/10.3390/ijms21061929.`,
    `Küster, Anette, and Nicole Adler. “Pharmaceuticals in the Environment: Scientific Evidence of Risks and Its Regulation.” <em>Philosophical Transactions of the Royal Society B: Biological Sciences</em>, vol. 369, no. 1656, 19 Nov. 2014, p. 20130587, www.ncbi.nlm.nih.gov/pmc/articles/PMC4213597/,https://doi.org/10.1098/rstb.2013.0587`,
    `NHS England. <em>Pharmaceutical Waste Reduction in the NHS</em>. 2015.`,
    `Polianciuc, Svetlana Iuliana, et al. “Antibiotics in the Environment: Causes and Consequences.” <em>Medicine and Pharmacy Reports</em>, vol. 93, no. 3, 29 July 2020, www.ncbi.nlm.nih.gov/pmc/articles/PMC7418837/, https://doi.org/10.15386/mpr-1742.`,
  ],
}
